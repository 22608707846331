import API from '@/api'

const state = {
	item: {},
	promises: {
		fetch: null,
	},
}

const mutations = {
	set(state, payload) {
		state.item = payload
	},
	setPromise(state, { key, promise }) {
		state.promises[key] = promise
	},
}

const actions = {
	fetch({ commit, state }) {
		if (state.item.id) return state.item
		let promise = API.getPost().then(data => {
			commit('set', data)
			return data
		})
		commit('setPromise', { key: 'fetch', promise })
		return promise
	},
}

const getters = {}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
