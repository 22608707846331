<script setup>
import { computed, ref } from 'vue'
import Photoswipe from '@kvass/pagebuilder/src/components/Photoswipe.vue'

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
  gallery: {
    type: Array,
    required: true,
  },
})

const images = computed(() => props.gallery)

const element = ref()

function trigger() {
  let firstSlide = element.value.querySelector('.kpb-photoswipe__item')
  if (!firstSlide) return
  firstSlide.click()
}
</script>

<template>
  <div class="project-card-gallery" ref="element">
    <Photoswipe v-if="images" v-show="false" :data="images" />
    <button class="project-card-gallery__button" @click="trigger">
      <FontAwesomeIcon :icon="['fal', 'expand-arrows']" />
      Åpne bildegalleri ({{ images.length }})
    </button>
    <div
      v-if="
        $path('customFields.status', project) && $path('customFields.status', project) !== 'none'
      "
      class="project-card-gallery__status"
    >
      {{ $t(`residentialStatus:rent.${$path('customFields.status', project)}`) }}
    </div>
    <img class="project-card-gallery__thumbnail" :src="images[0].url" alt="" @click="trigger" />
  </div>
</template>

<style lang="scss">
.project-card-gallery {
  height: 100%;
  background-color: #f5f5f5;

  &__thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  &__status {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background-color: $yellow;
    color: $primary;
    padding: 0.5em 1.2em;
  }
  &__button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-color: $fersken;
    color: $primary;
    padding: 0.5em 1.2em;
    border-radius: 999px;
    display: flex;
    gap: 0.6rem;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.875em;

    &:hover {
      background-color: rgba($fersken, 0.8);
    }

    @include respond-below('phone') {
      top: 0.6rem;
      left: 0.6rem;
    }
  }
}
</style>
