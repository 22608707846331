<template>
  <div class="page-overview">
    <h1>{{ $path('customFields.portfolio-title', item) || item.name }}</h1>
    <h2>Utvalgte eiendommer</h2>
    <div
      :class="
        `page-overview__projects page-overview__projects--amount-${columns === 2 ? 'few' : 'many'}`
      "
    >
      <div class="page-overview__projects-column" v-for="(col, i) in splitProjects" :key="i">
        <router-link
          class="page-overview__project"
          v-for="project in col"
          :key="project.id"
          :to="{ path: '/', hash: getLink(project.name) }"
        >
          <h3>{{ project.name }}</h3>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Slugify } from '@/utils'
import { computed } from 'vue'

const props = defineProps({
  projects: Array,
})

function getLink(projectName) {
  return '#' + Slugify(projectName)
}

const columns = computed(() => (props.projects.length >= 10 ? 3 : 2))
const projectsPerColumn = computed(() => Math.ceil(props.projects.length / columns.value))

const splitProjects = computed(() => {
  const result = []

  for (let i = 0; i < columns.value; i++) {
    result[i] = props.projects.slice(
      i * projectsPerColumn.value,
      i * projectsPerColumn.value + projectsPerColumn.value,
    )
  }

  return result
})
</script>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Page', ['item']),
  },
}
</script>

<style lang="scss">
.page-overview {
  --subtle-border: 1px solid #{rgba($burgund, 0.2)};

  padding: 2rem;
  background-color: $hvit;
  position: relative;
  pointer-events: initial;

  @include respond-below('phone') {
    padding: 0rem;
  }

  @include respond-above('phone') {
    border-top: 1px solid #aeb2b6;
    border-top-left-radius: 3px;

    &::before {
      content: '';
      top: 1px;
      left: 0;
      height: 132px;
      position: absolute;
      border-left: 1px solid #aeb2b6;
    }
  }

  h1 {
    margin: 0;
    position: relative;
  }

  &__projects {
    display: grid;
    gap: 0 1rem;
    grid-template-columns: repeat(var(--columns), 1fr);

    @include respond-below('phone') {
      --columns: 1 !important;
    }

    &--amount-few {
      --columns: 2;
    }

    &--amount-many {
      --columns: 3;
    }

    &-column {
      & > .page-overview__project:first-child {
        border-top: var(--subtle-border);
      }
    }
  }

  &__project {
    text-decoration: none;
    border-bottom: var(--subtle-border);
    padding: 0.8rem;
    display: block;

    h3 {
      margin: 0;

      .page-overview__projects--amount-many & {
        font-size: 1rem !important;
      }

      @include respond-below('phone') {
        font-size: 1rem;
      }
    }
  }
}
</style>
