<template>
  <Loader :value="promises.fetch" theme="default">
    <div v-if="item" class="page">
      <Blocks :blocks="config" :custom-components="customComponents" />
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BrowserApiMixin from '@/mixins/browser-api'

// pagebuilder
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/page.js'
import PageOverview from '@/components/Blocks/PageOverview'
import ProjectCardList from '@/components/Blocks/ProjectCardList'
import OverviewMap from '@/components/Blocks/Map'

export default {
  mixins: [BrowserApiMixin('page')],
  data() {
    return {
      iconMap: {
        flexibleSolutions: { icon: ['fa', ''], size: '2x' },
      },
    }
  },
  computed: {
    ...mapState('Page', ['item', 'promises']),
    config() {
      return config.call(this)
    },
    customComponents() {
      return { PageOverview, ProjectCardList, OverviewMap }
    },
  },
  methods: {
    ...mapActions('Page', ['fetch']),
  },
  mounted() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }

  --page-gradient: 2%;

  background-image: linear-gradient(
    180deg,
    var(--primary) var(--page-gradient),
    transparent var(--page-gradient)
  );

  @include respond-below('phone') {
    --page-gradient: 1.5%;
  }

  .section-info {
    position: relative;
    padding-bottom: 0;
    pointer-events: none;

    @include respond-above('tablet') {
      margin-top: -225px;
    }
  }
}
</style>
