<template>
  <article
    :id="id"
    :class="['project-card', { 'project-card--more-info': showMoreInfo }]"
    v-auto-animate
  >
    <ScrollAnchor :value="id" offset="-8rem" />

    <div class="project-card__grid">
      <div class="project-card__info">
        <div class="project-card__info-content">
          <h2>{{ project['project-name'] }}</h2>
          <p>{{ portfolioComment.value }}</p>
          <hr />
        </div>

        <ul class="project-card__info-items">
          <li
            v-for="item in infoItems"
            :key="item.label"
            :class="['project-card__info-item', `project-card__info-item-type--${item.key}`]"
          >
            <h4>{{ item.label }}</h4>
            <component :is="item.href ? 'a' : 'p'" :href="item.href">{{ item.value }}</component>
          </li>
        </ul>
        <div class="project-card__actions">
          <MediaTrigger
            v-if="portfolioFloorplan.length"
            :label="`Planløsning (${portfolioFloorplan.length})`"
            theme="primary"
            :data="portfolioFloorplan"
          />

          <ButtonComponent
            v-if="project.url || project.customFields['key-info'].homepage"
            theme="secondary"
            label="Hjemmeside"
            tag="a"
            target="_blank"
            :href="project.url || project.customFields['key-info'].homepage"
            :icon="['far', 'external-link-alt']"
          />
          <ButtonComponent
            v-if="portfolioButton.label && portfolioButton.href"
            theme="secondary"
            :label="portfolioButton.label"
            tag="a"
            target="_blank"
            :href="portfolioButton.href"
            :icon="['far', 'external-link-alt']"
          />
        </div>
        <button
          v-if="!hideShowMoreInfo"
          class="project-card__more-info-button"
          @click="showMoreInfo = !showMoreInfo"
        >
          <h3>Mer informasjon</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        </button>
      </div>
      <ProjectCardGalleryMap
        class="project-card__map"
        :project="project"
        :gallery="portfolioGallery"
      />
    </div>

    <div v-if="showMoreInfo" class="project-card__more-info">
      <ul class="project-card__more-info-items">
        <li
          v-for="(item, index) in portfolioCustomContent"
          :key="item.key + index"
          class="project-card__info-item"
        >
          <h4>{{ item.label }}</h4>
          <p>{{ item.value }}</p>
        </li>
      </ul>
      <div v-if="rentFacilities.features && rentFacilities.features.length">
        <span style="font-weight: 500;">Fellesfasiliteter</span>
        <ul>
          <li v-for="feature in rentFacilities.features" :key="feature">
            {{ $t(feature) }}
          </li>
        </ul>
      </div>

      <div>
        <ul class="project-card__more-info-additional-info">
          <li
            v-for="(item, index) in additionalInfo"
            :key="item.key + index"
            class="project-card__info-item"
          >
            <h4>{{ item.label }}</h4>
            <p>{{ item.value }}</p>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="!hideShowMoreInfo" class="project-card__more-info-button--mobile">
      <button class="project-card__more-info-button" @click="showMoreInfo = !showMoreInfo">
        <h3>Mer informasjon</h3>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
      </button>
      <div v-if="showMoreInfo" class="project-card__more-info">
        <ul class="project-card__more-info-items">
          <li
            v-for="(item, index) in portfolioCustomContent"
            :key="item.key + index"
            class="project-card__info-item"
          >
            <h4>{{ item.label }}</h4>
            <p>{{ item.value }}</p>
          </li>
        </ul>
        <div class="project-card__more-info-facilities">
          <span style="font-weight: 500;">Fellesfasiliteter</span>
          <ul>
            <li v-for="feature in rentFacilities.features" :key="feature">
              {{ $t(feature) }}
            </li>
          </ul>
        </div>
        <ul class="project-card__more-info-additional-info">
          <li
            v-for="(item, index) in additionalInfo"
            :key="item.key + index"
            class="project-card__info-item"
          >
            <h4>{{ item.label }}</h4>
            <p>{{ item.value }}</p>
          </li>
        </ul>
      </div>
    </div>
  </article>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { Slugify } from '@/utils'
import ProjectCardGalleryMap from './ProjectCardGalleryMap.vue'
import MediaTrigger from '@kvass/pagebuilder/src/components/MediaTrigger.vue'
import ScrollAnchor from '@kvass/scroll-anchor'

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
})

const showMoreInfo = ref(false)
const hideShowMoreInfo = computed(
  () =>
    !(
      portfolioComment.value.value ||
      rentFacilities.value.features ||
      portfolioCustomContent.value.length
    ),
)

const id = computed(() => Slugify(props.project.name))

const salesman = computed(() => props.project.roles?.salesman[0] || {})

const projectAddress = computed(
  () =>
    props.project.address &&
    [props.project.address.street, props.project.address.postcode, props.project.address.city].join(
      ', ',
    ),
)
const projectRentProperties = computed(
  () =>
    props.project.customFields['project-rent-properties'] ||
    props.project.customFields['key-info'] ||
    {},
)

const rentProperties = computed(
  () =>
    props.project.customFields['rent-properties'] || props.project.customFields['key-info'] || {},
)
const rentLocation = computed(
  () => props.project.customFields['rent-location'] || props.project.customFields['key-info'] || {},
)
const rentFacilities = computed(() => ({
  ...(props.project.customFields['rent-facilities'] ||
    props.project.customFields['key-info'] ||
    {}),
  features: [
    ...(
      (
        props.project.customFields['rent-facilities'] ||
        props.project.customFields['key-info'] ||
        {}
      ).features || []
    ).map(i => 'rent-facilities:' + i),
    projectRentProperties.value?.numberOfParkingSpots
      ? `Parkering (${projectRentProperties.value?.numberOfParkingSpots} plasser)`
      : '',
  ].filter(Boolean),
}))

function filterNumber(number) {
  if (!number) return
  return number.toLocaleString().replace(',', '.')
}
const totalArea = computed(() => {
  let data = projectRentProperties.value
  if (!data || !data.areaFrom) return ''

  return `Fra ${filterNumber(data.areaFrom)} ${
    data.areaTo ? 'til ' + filterNumber(data.areaTo) : ''
  } kvm`
})

const portfolioFloorplan = computed(() => props.project['portfolio-floorplan'] || [])
const portfolioGallery = computed(
  () =>
    [
      props.project['portfolio-gallery'],
      [
        ...(props.project.customFields?.cover || []),
        ...(props.project.customFields?.gallery || []),
      ],
      props.project.media?.gallery,
    ]
      .filter(Boolean)
      .filter(p => p.length)[0],
)
const portfolioCustomContent = computed(
  () =>
    [
      ...props.project['portfolio-custom-content'],
      { label: 'Byggeår', value: projectRentProperties.value.constructionYear },
    ]
      ?.filter(
        k =>
          ![
            'Kommentar',
            'kommentar',
            'comment',
            'area-offer',
            'area-total',
            'totalt-areal-bygg',
            'estimated-rental-price',
          ].includes(k.key),
      )
      .map(i => {
        return {
          ...i,
          label: i.label.replace('kr/kvm/år*', 'kvm/år*'),
        }
      })
      .filter(i => Boolean(i.value)) || [],
)
const portfolioComment = computed(
  () =>
    props.project['portfolio-custom-content']?.find(k =>
      ['Kommentar', 'kommentar', 'comment'].includes(k.key),
    ) || {},
)

const portfolioButton = computed(() => {
  return {
    label: props.project['custom-button-label'],
    href: props.project['custom-button-url'],
  }
})

const additionalInfo = computed(() =>
  [
    { label: 'Megler', value: salesman.value.name, key: 'salesman' },

    {
      label: 'Telefon',
      value: salesman.value.phone,
      href: `tel:${salesman.value.phone}`,
      key: 'phone',
    },

    {
      label: 'E-post',
      value: salesman.value.email,
      href: `mailto:${salesman.value.email}`,
      key: 'email',
    },
    { label: 'Gårdeier', value: rentProperties.value.owner },
  ].filter(item => !!item.value),
)

const infoItems = computed(() =>
  [
    {
      label: 'Området',
      value: (rentLocation.value.location || []).join(', '),
      key: 'location',
    },
    { label: 'Adresse', value: projectAddress.value },
    // { label: 'Totalt leieareal kvm BTA', value: totalArea.value, key: 'total-area' },

    { label: 'Innflytting', value: rentProperties.value.acquisition },

    { label: 'Miljøsertifisert', value: rentProperties.value.environmentallyCertified },
    ...(props.project['portfolio-custom-content']?.filter(k =>
      ['area-offer', 'area-total', 'estimated-rental-price', 'totalt-areal-bygg'].includes(k.key),
    ) || []),
  ].filter(item => Boolean(item.value)),
)
</script>

<style lang="scss">
.project-card {
  background-color: $rosaLys;
  padding: 1.2rem;
  border-radius: 3px;

  &__grid {
    display: grid;
    gap: 1.2rem;
    grid-template-columns: repeat(2, 1fr);

    @include respond-below('phone') {
      grid-template-columns: 1fr;
    }
  }

  &--more-info {
    grid-template-rows: repeat(2, auto);
  }

  &__map {
    height: 100%;

    @include respond-below('phone') {
      grid-row-start: 1;
    }
  }

  &__info {
    &-content {
      margin-bottom: 2rem;
      h2 {
        margin-bottom: 0.5rem;
      }
      p {
        margin: 0;
      }
      hr {
        margin-top: 1rem;
        border-color: rgba($burgund, 0.2);
      }
    }

    &-items {
      display: grid;

      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: dense;

      gap: 2.75rem;
      padding: 0;
      margin: 0;

      @include respond-below('phone') {
        gap: 1.5rem;
      }
    }

    &-item {
      list-style: none;
      padding: 0;

      h4,
      p,
      a {
        margin: 0;
      }

      p,
      a {
        display: block;
        font-weight: 500;
        line-height: 1.2;
        margin-block: 0.2rem;
      }

      &-type {
        &--phone,
        &--email,
        &--salesman {
          grid-column: 1;
        }
      }
    }
  }

  &__actions {
    display: flex;
    gap: 1rem;
    margin-block: 2.5rem;

    .elder-button {
      text-align: start;

      @include respond-below('phone') {
        width: 100%;
      }
    }

    @include respond-below('phone') {
      flex-direction: column-reverse;
      margin-bottom: 1rem;
    }
  }

  &__more-info-button {
    cursor: pointer;
    color: inherit;
    width: 100%;
    padding-block: 0.75rem;
    border-block: 1px solid rgba($burgund, 0.2);

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    @include respond-below('phone') {
      display: none;

      .project-card__more-info-button--mobile & {
        display: flex;
        border: 1px solid;
        border-radius: $border-radius;
        padding: 0.75rem 1.5rem;

        h3 {
          font-size: 1rem;
        }

        svg {
          height: 1.5rem !important;
        }
      }
    }

    h3 {
      margin: 0;
    }

    svg {
      height: 2rem;
      transition: rotate 100ms ease-out;
    }

    .project-card--more-info & {
      @include respond-above('phone') {
        border-bottom: 1px solid transparent;
      }

      svg {
        rotate: 45deg;
      }
    }
  }

  &__more-info-button--mobile {
    border: 1px solid currentColor;
    border-radius: inherit;

    .project-card__more-info-button {
      border: none !important;
    }

    @include respond-above('phone') {
      display: none;
    }

    .project-card__more-info {
      display: block;
      position: relative;
      padding-inline: 1.5rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        inset-inline: 1.5rem;
        border-bottom: 1px solid rgba($burgund, 0.2);
      }
    }
  }

  &__more-info {
    $gap: 2.5rem;
    padding-block: 1rem;

    @include respond-above('phone') {
      grid-column: span 2;
      border-bottom: 1px solid rgba($burgund, 0.2);
      grid-template-columns: repeat(4, 1fr);
    }

    @include respond-below('phone') {
      display: none;
    }

    display: grid;
    gap: 2rem;

    &-facilities {
      @include respond-below('phone') {
        margin: $gap 0;
      }
    }

    &-items {
      @include respond-above('phone') {
        grid-column: span 2;
      }

      display: grid;
      gap: $gap;
      padding: 0;
      margin: 0;

      @include respond-above('phone') {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &-additional-info {
      display: grid;
      gap: $gap;
      padding: 0;
      margin: 0;
    }
  }
}
</style>
