<template>
	<div class="project-card-list">
		<ProjectCard v-for="project in projects" :key="project.id" :project="project" />
	</div>
</template>

<script>
import ProjectCard from './ProjectCard.vue'
import { mapState } from 'vuex'

export default {
	props: {
		projects: Array
	},
	computed: {
		...mapState('Root', ['item']),
	},
	components: {
		ProjectCard
	}
}
</script>

<style lang="scss">
.project-card-list {
	display: grid;
	gap: 3.75rem;

	@include respond-below('phone') {
		gap: 2rem;
	}
}
</style>