<template>
	<Map :data="mapData" :map-options="mapOptions" :fetch-projects="false" aspect-ratio="unset" />
</template>

<script>
import Map from '@/components/Blocks/Map'

export default {
	props: {
		project: {
			type: Object,
			required: true
		}
	},
	computed: {
		mapData(){
			const coordinates = this.$path('address.location.coordinates', this.project) 
			return {
				coordinates: coordinates && coordinates.length ?  coordinates : [10.744436,59.910779] ,
			}
		},
		mapOptions(){
			return {
				dragPan: true,
				zoom: 15
			}
		}
	},
	components: {
		Map
	}
}
</script>

<style lang="scss">
	
</style>