import API from '@/api'

const state = {
  item: {},
  promises: {
    fetch: null,
  },
}

const mutations = {
  set(state, payload) {
    state.item = payload
  },
  setPromise(state, { key, promise }) {
    state.promises[key] = promise
  },
}

function fetchProjects(projectIds = [], externalProjectIds = [], portfolioList = []) {
  return API.getProjects(projectIds)
    .then(async projects => {
      const externals = await API.getExternalProjects(externalProjectIds)

      return portfolioList
        .map(project => [...projects, ...externals].find(p => p.id === project['project-id']))
        .filter(Boolean)
    })
    .then(projects => {
      const merged = projects.map(project => {
        const info = portfolioList.find(item => item['project-id'] === project.id)

        return {
          ...project,
          ...info,
        }
      })

      return merged
    })
}

const actions = {
  fetch({ commit, state }) {
    if (state.item.id) return state.item
    let promise = API.getPage().then(async data => {
      const customFields = data.customFields
      const projects = await fetchProjects(customFields['selected-projects'], customFields['selected-external-projects'], customFields['portfolio-project-list'])
      return {
        ...data,
        projects
      }
    }).then(data => {
      commit('set', data)
      return data
    })
    commit('setPromise', { key: 'fetch', promise })
    return promise
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
