<template>
  <Card v-if="url" class="map-card-popup" theme="flat" :thumbnail="imageUrl">
    <template v-if="!imageUrl" #header>
      <FontAwesomeIcon :icon="['fal', 'house']" size="3x" style="opacity: 0.2" />
    </template>

    <template #footer>
      <p class="map-card-popup__title">{{ name }}</p>

      <ButtonComponent
        v-if="url"
        theme="secondary"
        tag="a"
        :href="url"
        label="Se eiendom"
        :icon="['far', 'arrow-down']"
      />
    </template>
  </Card>
</template>

<script>
import Card from '@kvass/card'
import { ButtonComponent } from 'vue-elder-button'

export default {
  props: {
    url: String,
    imageUrl: String,
    name: String,
  },
  components: {
    Card,
    ButtonComponent,
  },
}
</script>

<style lang="scss">
.map-card-popup {
  max-width: 100%;
  max-height: 100%;
  min-width: 200px;

  .kvass-card__content {
    text-align: center;
  }

  .kvass-card {
    padding: 0;

    &__header {
      padding: 0px;
      border-bottom: unset;
      min-height: auto;
      aspect-ratio: 16/9;
      min-width: 200px;
      object-fit: contain;
    }

    &__footer {
      background-color: white;
      border-top: unset;
      --kvass-card-spacing: 0.75rem 0.75rem;
    }

    &__footer-content {
      gap: 0rem;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      
      .elder-button {
        font: inherit;
        font-weight: 500;
      }
    }
  }

  &__title {
    line-height: 1;
    margin-top: 0;
  }
}
</style>
