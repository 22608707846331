<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import ProjectCardMap from './ProjectCardMap.vue';
import ProjectCardGallery from './ProjectCardGallery.vue';

const props = defineProps({
	project: {
		type: Object,
		required: true
	},
	gallery: {
		type: Array,
	}
})

const showMap = ref(false)
const dynamicComponent = computed(() => showMap.value || !props.gallery ? ProjectCardMap : ProjectCardGallery)

const thumbnail = computed(() => showMap.value ? (props.project.cover?.[0]?.url || props.gallery?.[0]?.url || props.project.gallery?.[0]?.url || props.project.media?.cover?.[0]?.url) : '/assets/img/map.png')

const icon = computed(() => !showMap.value ? ['fal', 'map'] : ['fal', 'image'])

const root = ref()
const rootHeight = ref(0)

onMounted(() => {
	rootHeight.value = root.value.getBoundingClientRect().height
	showMap.value = !props.gallery?.length
})

watch(() => root.value?.getBoundingClientRect().height, (val) => {
	if(!val) return
	rootHeight.value = val
})
</script>

<template>
	<div class="project-card-gallery-map" ref="root">
		<button v-if="gallery && gallery.length" class="project-card-gallery-map__switcher" @click="showMap = !showMap">
			<img :src="thumbnail" alt="" />
			<span>
				<FontAwesomeIcon :icon="icon" />
				{{ showMap ? 'Bilder' : 'Kart' }}
			</span>
		</button>
		<KeepAlive>
			<component :is="dynamicComponent" :project="project" :gallery="gallery" :key="showMap" :style="`height: ${rootHeight}px`" />
		</KeepAlive>
	</div>
</template>

<style lang="scss">
.project-card-gallery-map {
	position: relative;
	border-radius: 3px;
	overflow: hidden;
	height: 100%;
	display: flex;
	
	@include respond-below('phone') {
		aspect-ratio: 1;
	}

	&__switcher {
		box-shadow: 0 6px 12px -4px rgba($burgund, .2);
		background-color: white;
		position: absolute;
		cursor: pointer;
		aspect-ratio: 1;
		width: 6rem;
		padding: .25rem;
		right: 1rem;
		top: 1rem;
		border-radius: 3px;
		z-index: 10;
		display: flex;

		@include respond-below('phone') {
			top: .6rem;
			right: .6rem;
		}

		span {
			background: linear-gradient(to top, rgba(black, .75), transparent);
			position: absolute;
			inset: 0;
			margin: .25rem;
			color: white;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			display: flex;
			gap: .5rem;
			align-items: last baseline;
		}

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
}
</style>